<!--  -->
<template>
  <el-dialog
  title="回复"
  :visible.sync="dialogVisible"
  width="30%"
  :before-close="handleClose">
  <div>
    <el-input
      type="textarea"
      :rows="2"
      placeholder="请输入回复内容"
      v-model="msg">
    </el-input>
  </div>
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="ensure">确 定</el-button>
  </span>
</el-dialog>
</template>

<script>
import {problemAnswer} from '../../../api/service'
export default {
  data () {
    return {
      dialogVisible:false,
      msg:'',
      problemId:'',
    };
  },
  components: {},
  computed: {},
  methods: {
    handleClose(done){
      this.dialogVisible = false;
      done();
    },
    ensure(){
      let para={
        problemId:this.problemId,
        msg:this.msg
      }
      problemAnswer(para).then(res=>{
        let data = res.data;
        if(data.code === '000'){
          this.$message.success(data.desc)
          this.msg = '';
          this.dialogVisible = false;
          this.$parent.getList();
        }else{
          this.$message.warning(data.desc)
          console.log(data.desc)
        }
      })
    }
  }
}

</script>
<style lang='scss' scoped>
</style>