<!-- 客服工单详情 -->
<template>
  <el-dialog
  title="工单详情"
  :visible.sync="dialogVisible"
  width="50%"
  :before-close="handleClose">
  <div>
    <el-descriptions title="用户信息">
    <el-descriptions-item label="问题类型">{{info.problemType}}</el-descriptions-item>
    <el-descriptions-item label="相关ID">{{info.casePublishId}}</el-descriptions-item>
    <el-descriptions-item label="提交用户名">{{info.accountName}}</el-descriptions-item>
    <el-descriptions-item label="提交用户ID">{{info.accountId}}</el-descriptions-item>
    <el-descriptions-item label="最后提交时间">
      {{info.updateTime}}
    </el-descriptions-item>
    </el-descriptions>
  </div>
  <div class="dialog-dv">
    <div class="dialog-item" :class="item.accountType==0?'right':'left'" v-for="(item,index) in infoList" :key="item.id">
      <div class="item">
        <div class="title">
          <span class="time">{{item.replyTime}}</span>
          <img class="img" :src="item.headImgUrl" style="width:26px;height:26px;border-radius:50%;">
          <span class="name">{{item.accountName}}</span>
        </div>
        <p>{{item.msg}}</p>
        <div class="imgs">
          <a :href="url" target="_blank" style="margin-right:10px;" v-for="(url,index) in item.img" :key="index">
            <img :src="url" alt="" style="width:50px;height:50px;">
          </a>
        </div>
      </div>
    </div>
  </div>
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">关 闭</el-button>
  </span>
</el-dialog>
</template>

<script>
import {problemDetail} from '../../../api/service'
export default {
  data () {
    return {
      dialogVisible:false,
      infoList:[],
    };
  },
  components: {},
  computed: {},
  props:['info'],
  methods: {
    handleClose(done) {
      this.dialogVisible = false;
      done();
    },
    getDetail(id){
      problemDetail({problemId:id}).then(res=>{
        let data = res.data;
        if(data.code === '000'){
          this.infoList = data.content.list;
        }else{
          console.log(data.desc)
        }
      })
    }
  }
}

</script>
<style lang='scss' scoped>
.dialog-dv{
  padding:20px 15px;
  margin-top:15px;
  height: 350px;
  overflow-y:auto;
  border: 1px solid #ebebeb;
  border-radius: 3px;
  .dialog-item{
    margin-bottom: 10px;
    width: 100%;
    display: inline-block;
    .item{
      width:45%;
      float: left;
    }
    p{
      background: #fafafa;   
      border: 1px solid #ebeef5;
      padding: 12px 10px;
      border-radius: 10px;
      margin-bottom: 10px;
      }
    .title{
      padding-bottom: 10px;
      position:relative;
      display: inline-block;
      width:100%;
      .name{
        float:right;
      }
      .img{
        position:absolute;
        top:-5px;
        right:-36px;
      }
    }
  }
  .dialog-item.right{
    .item{
      float: right;
    }
    .title{
      .name{
        float:left;
      }
      .time{
        float:right;
      }
      .img{
        left:-35px;
      }
    }
  }
}
</style>