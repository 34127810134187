<template>
  <div class="user-manage">
    <table-search :total="total" :conditions="conditions" @searchRes="searchRes" @refresh="refresh">
    </table-search>
    <div class="table">
      <el-table
        v-loading="loading"
        :data="tableData"
        border
        @filter-change="filterChange"
        style="width: 100%">
        <el-table-column
          prop="id"
          label="工单ID">
        </el-table-column>
        <el-table-column
          prop="problemDesc"
          label="问题描述">
        </el-table-column>
        <el-table-column
          prop="problemType"
          :filters="subjectFilter"
          column-key="problemType"
          label="问题类型">
          <template slot-scope="scope">
            <span :style="{'color':COLOR.blue}">{{scope.row.problemType}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="casePublishId"
          label="问题相关ID">
        </el-table-column>
        <el-table-column
          prop=""
          label="最后提交时间">
          <template slot-scope="scope">
            <span >{{scope.row.msgVo && scope.row.msgVo.replyTime}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="accountId"
          label="用户ID">
        </el-table-column>
        <el-table-column
          prop="problemNumber"
          label="复问数">
        </el-table-column>
        <el-table-column
          prop="status"
          :filters="payFilter"
          column-key="status"
          label="状态">
          <template slot-scope="scope">
            <span :style="{'color':COLOR.red}" v-show="scope.row.status==0">等待回复</span>
            <span :style="{'color':COLOR.green}" v-show="scope.row.status==1">已回复</span>
            <span v-show="scope.row.status==2">已关闭</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="val"
          label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="viewHandle(scope.row)">查看详情</el-button>
            <el-divider direction="vertical" v-if="scope.row.status==0"></el-divider>
            <el-button type="text" @click="replyHandle(scope.row)" v-if="scope.row.status==0">回复</el-button>
            <el-divider direction="vertical" v-if="scope.row.markFlag == 0"></el-divider>
            <el-button type="text" @click="mark(scope.row)" v-if="scope.row.markFlag == 0">标记</el-button>
            <el-divider direction="vertical" v-if="scope.row.markFlag == 1"></el-divider>
            <el-button type="text" @click="unmark(scope.row)" v-if="scope.row.markFlag == 1">取消标记</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer flex-row">
      <div class="page">
        <el-pagination
          background
          :page-size="form.perPage"
          @size-change="handleSizeChange"
          layout="prev, pager, next,sizes,jumper"
          :page-sizes="[10, 20, 30, 40]"
          @current-change="currentChange"
          :total="total">
        </el-pagination>
      </div>
    </div>
    <customer-detail ref="CustomerDetail" :info="info"></customer-detail>
    <reply-dialog ref="ReplyDialog"></reply-dialog>
  </div>
</template>

<script>
import TableSearch from '../../components/table-search.vue'
import {problemList,markHandle,unmarkHandle} from '../../api/service'
import CustomerDetail from './coms/customer-detail.vue'
import ReplyDialog from './coms/reply-dialog.vue'
export default {
  data () {
    return {
      loading:false,
      total:100,
      info:{},
      form:{
        page:1,
        perPage:10,
        problemType:'',
        status:''
      },
      conditions:[
        {value:'problemId',label:'工单ID'},
        {value:'casePublishId',label:'问题相关ID'},
        {value:'accountId',label:'用户ID'},
      ],
      tableData:[
        {
          val:'1',
          id:1,
          status:'1'
        },
        {
          val:'2',
          id:2,
          status:'2'
        },
        {
          val:'3',
          id:3,
          status:'3'
        },
        {
          val:'4',
          id:4,
          status:'4'
        }
      ],
      multipleSelection:[],
        statusFilter:[
          {text: '正常', value: '1'}, 
          {text: '禁言', value: '2'}, 
          {text: '禁止登陆', value: '3'}
        ],
        applyFilter:[
          {text: '诉讼业务', value: '1'}, 
          {text: '婚姻家庭', value: '2'}, 
          {text: '合同纠纷', value: '3'},
          {text: '非诉讼业务', value: '4'},
          {text: '法律顾问', value: '5'},
          {text: '劳动纠纷', value: '6'},
          {text: '金融证券', value: '7'},
          {text: '公司经营', value: '8'},
          {text: '法律文书', value: '9'},
        ],
        typeFilter:[
            {text: '等待选标中', value: '1'},
            {text: '已中标', value: '2'}, 
            {text: '未中标', value: '3'}
        ],
        subjectFilter:[
            {text: '意见建议', value: 'suggest'},
            {text: '招标求助问题', value: 'invite_bidding'}, 
            {text: '投标报名问题', value: 'bidding'}, 
            {text: '软件BUG', value: 'bug'}, 
            {text: '认证问题', value: 'identity'}
        ],
        // subjectFilter:[
        //     {text: '意见建议', value: '意见建议'},
        //     {text: '招标/求助问题', value: '招标/求助问题'}, 
        //     {text: '投标/报名问题', value: '投标/报名问题'}, 
        //     {text: '软件BUG', value: '软件BUG'}, 
        //     {text: '认证问题', value: '认证问题'}
        // ],
        payFilter:[
            {text: '已回复', value: 1},
            {text: '等待回复', value: 0}, 
            {text: '已关闭', value: 2}, 
        ]

    };
  },
  components: {
    TableSearch,
    CustomerDetail,
    ReplyDialog
  },
  computed: {},
  methods: {
    viewHandle(row){
      this.info = {
        problemType:row.problemType,
        casePublishId:row.casePublishId,
        updateTime:row.msgVo.replyTime,
        accountId:row.accountId,
        accountName:row.msgVo.accountName
      }
      this.$refs.CustomerDetail.dialogVisible = true;
      this.$refs.CustomerDetail.getDetail(row.id);
    },
    searchRes(para){
      this.form = Object.assign({
        page:1,
        perPage:this.form.perPage,
        status:this.form.status,
        problemType:this.form.problemType
        },para);
      this.getList();
    },
    refresh(){
      this.getList();
    },
    getList(){
      this.loading = true;
      problemList(this.form).then(res=>{
        let data = res.data;
        if(data.code === '000'){
          this.tableData = data.content.list;
          this.loading = false;
          this.total = data.content.total;
        }else{
          console.log(data.desc)
        }
      })
    },
    // 分页
    currentChange(val){
      this.form.page = val;
      this.getList();
    },
    handleSizeChange(val){
      this.form.perPage = val;
      this.form.page = 1;
      this.getList();
    },
    addHandle(){},
    replyHandle(row){
      this.$refs.ReplyDialog.dialogVisible = true;
      this.$refs.ReplyDialog.problemId =row.id;
    },
    mark(row){
      markHandle({problemId:row.id}).then(res=>{
        let data = res.data;
        if(data.code === '000'){
          this.$message.success(data.desc);
          this.getList();
        }else{
          this.$message.warning(data.desc);
          console.log(data.desc)
        }
      })
    },
    unmark(row){
      unmarkHandle({problemId:row.id}).then(res=>{
        let data = res.data;
        if(data.code === '000'){
          this.$message.success(data.desc);
          this.getList();
        }else{
          this.$message.warning(data.desc);
          console.log(data.desc)
        }
      })
    },
    editHandle(row){},
    forbidAll(){},
    forbid(){},
    // filterHandler(value, row, column) {
    //   const property = column['property'];
    //   return row[property] === value;
    // },
    filterChange(filters){
      console.log(filters)
      for(let key in filters){
        this.form[key] = filters[key].join();
      }
      this.form.page = 1;
      this.getList();  
    },
  },
  created(){
    this.getList();
  }
}

</script>
<style lang='scss' scoped>
.footer{
  justify-content: flex-end;
  margin:15px 0;
}
.dot{
  font-size: 20px;
  padding-right: 5px;
  vertical-align:middle;
}
</style>